<template>
  <div>
    <section>
      <div v-if="items && items.length > 0">
        <v-card class="my-4 rounded-lg border" v-for="(item, index) in items" :key="index" elevation="0" hover ripple
          :to="`/s/questions/${item.id}`">
          <v-card-title class="d-flex justify-space-between align-center">
            <div>
              <span v-if="item.answers" class="blue lighten-5 blue--text text--darken-1 p-2 rounded-lg f12"> {{
                  item.answers.length
              }} {{ $t("Answer") }}</span>
              <span v-else class="blue lighten-5 blue--text text--darken-1 p-2 rounded-lg f12"> 0 {{ $t("Answer") }}</span>
            </div>
            <div>
              <span class="f13 font-weight-light"> {{ item.createDate | moment("from", "now") }} </span>
            </div>
          </v-card-title>

          <v-card-text>
            <p class="f14 text-justify" v-html="item.question"></p>
          </v-card-text>

          <v-divider class="m-0" dark></v-divider>

          <v-card-actions class="d-flex justify-space-between align-center">
            <div v-if="item.answers && item.answers.length">
              <div class="d-flex flex-row align-items-center">
                <section class="d-flex flex-row avatar-group right-to-left">
                  <span v-for="(answer, index) in item.answers.slice(0, 3)" :key="index"
                    class="avatars-group__item avatar">
                    <v-avatar size="36" color="primary" class="bordered">
                      <img :src="answer.doctorProfilePic" :alt="answer.doctorFullName ? answer.doctorFullName : ''"
                        v-if="answer.doctorProfilePic && index < 2" />

                      <span v-else-if="index < 2" class="white--text text-small">
                        {{ answer.doctorFullName ? answer.doctorFullName.slice(0, 1) : "" }}
                      </span>

                      <span v-else class="white--text text-small">
                        ...
                      </span>
                    </v-avatar>
                  </span>
                </section>
                <div>
                  <span class="f12 font-weight-normal ps-1" v-if="item.answers.length == 1">
                    {{ item.answers[0].doctorFullName ? item.answers[0].doctorFullName : '' }}
                  </span>
                  <span class="f12 font-weight-normal ps-1" v-if="item.answers.length > 1">
                    {{ item.answers.length }} {{ $t('specialist') }}
                  </span>
                </div>
              </div>
            </div>
            <div v-else>
              <span class="bg-harmonies text-paris-pink p-2 rounded-lg f12">
                {{ $t("Not answered") }}
              </span>
            </div>
            <div>
              <div class="text-left mt-1">
                <div class="d-flex d-inline-flex align-items-center border border-primary rounded primary--text" v-if="item.userLikeQuestions && !item.userLikeQuestions.find(x => x.mobile == $store.getters.mobileNumber)">
                  <iconly type="bold" name="heart" class="ms-1 me-0 wh-20"></iconly>
                  <span v-if="item.userLikeQuestions && item.userLikeQuestions.length" class="me-1 f12">
                    {{
                        item.userLikeQuestions.length
                    }}
                  </span>
                  <span v-else class="me-1 f12">0</span>
                </div>
                <div class="d-flex d-inline-flex align-items-center primary rounded white--text" v-else>
                  <iconly type="bold" name="heart" class="ms-1 me-0 wh-20" color="white"></iconly>
                  <span v-if="item.userLikeQuestions && item.userLikeQuestions.length" class="me-1 f12">
                    {{
                        item.userLikeQuestions.length
                    }}
                  </span>
                  <span v-else class="me-1 f12">0</span>
                </div>
                <div class=" d-flex d-inline-flex align-items-center border border-primary rounded primary--text ms-2">
                  <iconly type="bold" name="show" class="ms-1 me-0 wh-20"></iconly>
                  <span class="me-1 f12">{{ item.view }}</span>
                </div>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </div>

      <span class="text-center f16 d-block mt-8" v-else>
        {{$t('No results found')}}
      </span>

      <infinite-loading @infinite="infiniteHandler">
        <div slot="spinner">
          <!-- {{$t('Loading...')}} -->
          <v-icon>mdi-selection-ellipse spin</v-icon>
        </div>
        <div slot="no-more">
          <!-- {{$t('No more items')}} -->
          <v-icon>mdi-access-point-off</v-icon>
        </div>
        <div slot="no-results">
          <!-- {{$t('No results found')}} -->
          <v-icon>mdi-access-point-off</v-icon>
        </div>
      </infinite-loading>
    </section>
  </div>
</template>
<script>
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();


export default {
  data() {
    return {
      clinicId: null,

      items: [],
      page: 1,
      size: 12,
    };
  },
  methods: {
    getClinicAnswerQuestion(page, size) {
      apiService.getClinicAnswerQuestion(this.clinicId, page, size)
        .then((response) => {
          this.items = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    infiniteHandler($state) {
        apiService.getClinicAnswerQuestion(this.clinicId, this.page, this.size)
          .then((response) => {
            if (response.data.length) {
              response.data.forEach(element => {
                this.items.push(element);
              });

              this.page += 1;
              $state.loaded();
            }
            else {
              $state.complete();
            }
          })
          .catch((err) => {
            console.log(err);
            $state.error();
          });
    },
    gotoSingleQuestion(id) {
      this.$router.push(`/s/questions/${id}`);
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
  },
  mounted() {
    if(this.$route.params.clinicId){
      this.clinicId = this.$route.params.clinicId;
      this.getClinicAnswerQuestion(0, this.size);
    }
    else {
      this.$router.go(-1);
    }
  },
};
</script>